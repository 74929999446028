.reader-export-full-window {
    display:flex;
    flex-direction: column;
    justify-content:space-between;
    width:100%;
    height:100%;
    gap:10px;
}
.reader-export-full-window .both-lists {
    display: flex;
    flex-direction:column;
    flex-basis:0;
    flex-grow:1;
    gap:20px;
    padding:10px 0;
}

.reader-export-full-window .both-lists .list-container {
    padding:5px 0;
    flex-basis:0;
    flex-grow:1;
    display:flex;
    flex-direction: column;
}
.reader-export-full-window .both-lists .list-box {
    max-height:30vh;
    flex-grow:1;
    overflow:auto;
}

.reader-export-full-window .both-lists .table-name-text {
    font-weight: bold;
    font-size: 19px;
    padding:0 0 3px 2px;
}

.reader-export-full-window .button-container {
    display:flex;
    flex-shrink:0;
    justify-content:left;
    gap:5px;
}

.button-container button{
    background-color: transparent !important;    
}

.reader-export-full-window .button-container .p-button {
    padding:3px;
    font-size:13px;
}
.reader-export-full-window .list-box .p-listbox .p-listbox-list .p-listbox-item {
    padding:1vh 2vw;
    background-color: var(--color-grey-600) !important;
}

.reader-export-full-window .list-box .p-listbox .p-listbox-header {
    padding: 0;
}

.reader-export-full-window .list-box .item-template {
    display:flex;
    justify-content: space-between;
}

.data-export-loading {
    width:100%;
    height:100%;
    display:flex;
    justify-content:center;
    align-items:center;
}