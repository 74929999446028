.writer-history-full-window {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 15px;
  /*WHAT*/
  flex-direction: row;
}
.writer-history-full-window .left-side {
  overflow-y: auto;
  height: 100%;
  display: flex;
  justify-content: center;
  white-space: nowrap;
  flex-shrink: 0;
}

.button-container {
  display: flex;
  gap: 5px;
}

.writer-history-full-window .right-side {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.button-container-scheme-picker {
  display: flex;
}

.button-container-scheme-picker .tab-view-button {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 5px 10px;
  min-width: 90px;
}

.button-container-scheme-picker .tab-view-button:hover {
  background: #2f2f2f;
  border-radius: 8px 8px 0px 0px !important;
}

.button-container-scheme-picker .tab-view-button.active {
  background: #1e1e1e;
  border-radius: 8px 8px 0px 0px !important;
  font-weight: bolder;
  transition: 0.3s;
}
