.reader-backup {
    display:flex;
    height:75vh;
}

.reader-backup .data-table {
    overflow-y:auto;
    height:100%;
    flex-shrink:0;
}

.reader-backup .editor {
    flex-basis:0;
    flex-grow:1;
}

.reader-backup .p-datatable .p-datatable-thead > tr > th {
    padding:0.5rem 1rem;
}

.reader-backup .p-datatable .p-datatable-tbody > tr > td {
    padding: 0 1rem;
}

.reader-backup .p-button {
    transform:scale(80%)
}