.full-component {
    display: flex;
    flex-direction:column;
    gap:15px;
    justify-content:space-between;
    height:100%;
}

.full-component .status-data-table .p-datatable .p-datatable-tbody > tr > td {
    padding: 0.15rem 1rem;
}
.full-component .button-container {
    display:flex;
    gap:5px;
}

.full-component .status-data-table {
    display: flex;
    flex-direction:column;
    gap:20px;
    margin-top:5px;
}
.full-component .status-data-table .table-class .text-place{
    margin-bottom:5px;
    margin-left:2px
}