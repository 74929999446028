@font-face {
  font-family: "Inter Tight";
  src: local("Inter Tight"), url(./fonts/InterTight/InterTight-Regular.woff2) format("woff2");
}

body {
  margin: 0;
  font-family: "Inter Tight", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-primary-black);
}

html,
body {
  font-family: 'Inter Tight', sans-serif !important;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background: var(--color-grey-600);
}

code {
  font-family: "Inter Tight", source-code-pro, Menlo, Monaco, Consolas,
}
