.writer-add-full-window {
    width:100%;
    height:100%;

    display: flex;
    flex-direction: column;
    gap:30px;
    padding:10px 0;
}
.writer-add-full-window .top-side {
    display: flex;
    flex-wrap: wrap;
    gap:30px 10px;
}

.writer-add-full-window .bottom-side {
    display: flex;
    flex-wrap: wrap;
    gap:30px 10px;

}
.writer-add-full-window .button-wrapper {
    display: flex;
    justify-content: flex-end;
}

.writer-add-full-window .top-side .id-input {
    flex-basis: 0;
    flex-grow: 1;
}

.writer-add-full-window .top-side .name-input {
    flex-basis:0;
    flex-grow: 1;
}
.writer-add-full-window .top-side .p-inputtext {
    min-width:200px;
    width:100%;
}

.writer-add-full-window .bottom-side .input-schema-wrapper {
    flex-basis:0;
    flex-grow: 1;
}
.writer-add-full-window .bottom-side .process-schema-wrapper {
    flex-basis:0;
    flex-grow: 1;
}
.writer-add-full-window .bottom-side .output-schema-wrapper {
    flex-basis:0;
    flex-grow: 1;
}