:root {
  --left-side-width: 230px;
  --toolbar-width: 40px;
}

.data-writer {
  display: flex;
  height: 100%;
}

.data-writer .left-side {
  display: flex;
  flex-direction: column;
  width: var(--left-side-width);
  background: var(--color-grey-600) !important;
  border: none !important;
}

.data-writer .list-box {
  margin: 3px;
  min-height: 0;
  flex-basis: 0;
  flex-grow: 1;
  position: relative;
}

.data-writer .left-side .p-listbox .p-listbox-header {
  padding: 0;
}
.data-writer .p-listbox .p-listbox-list .p-listbox-item {
  padding: 0 0 0 15px;
}

.data-writer .right-side .json-window {
  background-color: #1e1e1e;
}

.data-writer .right-side {
  padding: 3px 0 0 0;
  display: flex;
  flex-direction: column;
  background: var(--color-grey-600) !important;
  width: calc(100% - var(--left-side-width) - var(--toolbar-width));
}

.data-writer .buttons-container {
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  background: var(--color-primary-blue) !important;
}

.data-writer .buttons-container .buttons-left-container {
  margin: 5px;
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  justify-content: left;
  gap: 5px;
}

.data-writer .tab-view-window {
  flex-basis: 0;
  flex-grow: 1;
  bottom: 0;

  display: flex;
  flex-direction: column;
}

.data-writer .tab-view-window .function-buttons-container {
  display: flex;
  justify-content: space-between;
  min-height: 30px;
}

.data-writer .tab-view-window .function-buttons-container-container {
  display: flex;
}

.data-writer .p-listbox .p-listbox-list .p-listbox-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.data-writer
  .tab-view-window
  .function-buttons-container-container
  .function-buttons-container-container-button {
  transform: scale(90%);
}

.data-writer .tab-view-window .button-container-scheme-picker {
  display: flex;
}

.data-writer .tab-view-window .button-container-scheme-picker .tab-view-button {
  background: var(--color-grey-600) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 5px 10px;
  min-width: 90px;
  border: 0px !important;
}

.data-writer .tab-view-window .json-window {
  flex-basis: 0;
  flex-grow: 1;
  bottom: 0;
}

.data-writer .tab-view-window .button-container-scheme-picker .first-button {
  border-radius: 0px !important;
  border-right: 0px solid #1e1e1e !important;
}
.data-writer .tab-view-window .button-container-scheme-picker .last-button {
  border-radius: 0px !important;
  border-left: 0px solid #1e1e1e !important;
}
.data-writer
  .tab-view-window
  .button-container-scheme-picker
  .tab-view-button:hover {
  background: #2f2f2f !important;
  border-radius: 8px 8px 0px 0px !important;
}
.data-writer
  .tab-view-window
  .button-container-scheme-picker
  .tab-view-button.active {
  background: #1e1e1e !important;
  font-weight: bolder;
  transition: 0.3s;
  border-radius: 8px 8px 0px 0px !important;

}

#f-button {
  border-right: 1px solid #1e1e1e;
}

.tool-box-container {
  padding: 5px 4px 5px 0;
  background: var(--color-grey-600) !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: var(--toolbar-width);
}

.tool-box-container .tool-box-top-container,
.tool-box-container .tool-box-bottom-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.data-writer .left-side .list-box-loading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-primary-blue);
  border-radius: 4px;
  border: 1px solid var(--color-primary-blue);
}

.data-writer
  .left-side
  .list-box
  .p-listbox
  .p-listbox-header
  .p-listbox-filter-container
  .p-inputtext {
    background-color: var(--color-grey-600) !important;
  }
.data-writer
  .left-side
  .list-box
  .p-listbox
  .p-listbox-header
  .p-listbox-filter-container
  .p-inputtext::placeholder,
.data-writer
  .left-side
  .list-box
  .p-listbox
  .p-listbox-header
  .p-listbox-filter-container
  .pi-search {
  color: var(--color-primary-white);
}

.data-writer .left-side .list-box .p-listbox {
  border: none !important;
}
