.data-collector {
  display: flex;
  height: 100%;
}

.data-collector .leftSide {
  display: flex;
  flex-direction: column;
  background: var(--color-grey-600);
  width: var(--left-side-width);
  padding: 3px;
}

.data-collector .treeList {
  min-height: 0;
  flex-basis: 0;
  flex-grow: 1;
  position: relative;
}

.treeList {
  .readers-tree-container {
    .p-tree-container {
      overflow: auto !important;
    }
  }
}

.data-collector .middle-side {
  background: var(--color-grey-600);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 5px 4px 5px 0;
  width: var(--toolbar-width);
  align-items: center;
}

.data-collector .middle-side .tool-box-top-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.data-collector .middle-side .tool-box-bottom-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.data-collector .rightSide {
  display: flex;
  flex-direction: column;
  width: calc(100% - var(--left-side-width) - var(--toolbar-width));
  background: #23263b;
}

.data-collector .json-window {
  flex-basis: 0;
  flex-grow: 1;
  bottom: 0;
}

.data-collector .buttons-container {
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
}

.data-collector .buttons-container .buttons-left-container {
  margin: 5px;
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  justify-content: left;
  gap: 5px;
}

.data-collector .buttons-container .buttons-right-container {
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  justify-content: right;
  gap: 5px;
}

.data-collector .leftSide .treeList .p-tree {
  margin: 0;
  padding: 0;
  height: 100%;
  background: var(--color-grey-700);
  border: none;
  color: white;
}

.data-collector
  .leftSide
  .treeList
  .p-tree
  .p-tree-container
  .p-treenode
  .p-treenode-content
  .p-tree-toggler {
  margin-right: 0.3rem;
  width: 0.8rem;
  height: 0.8rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: box-shadow 0.15s;
}

/*TREE NODE SELECT*/
.data-collector
  .p-tree
  .p-tree-container
  .p-treenode
  .p-treenode-content.p-highlight {
  background: var(--color-grey-600);
  color: var(--color-primary-white);
  font-weight: bolder;
  margin-left: 5px;
  border-radius: 8px 0px 0px 8px;
}

.data-collector
  .p-tree
  .p-tree-container
  .p-treenode
  .p-treenode-content
  .p-tree-toggler {
  background: transparent !important;
  color: var(--color-primary-white) !important;
  font-weight: bolder;
}

.data-collector
  .p-tree
  .p-tree-container
  .p-treenode
  .p-treenode-content:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.data-collector .p-tree .p-tree-container .p-treenode .p-treenode-content {
  padding: 0;
}

.data-collector .p-tree .p-treenode ul.p-treenode-children .p-treenode-leaf {
  padding: 2px 0;
}

.data-collector .p-tree .p-tree-filter-container .p-inputtext {
  margin-bottom: 0;
  background-color: var(--color-grey-600) !important;
  color: var(--color-primary-white) !important;
}

.data-collector .p-tree .p-tree-filter-container .p-inputtext::placeholder,
.data-collector .p-tree .p-tree-filter-container .pi-search {
  color: var(--color-primary-white) !important;
}

.data-collector .treeList .p-tree .p-tree-container {
  height: calc(100% - 36px);
  padding: 9px 0 8px 0;
}

.data-collector .treeList .p-tree .p-tree-container .p-treenode {
  margin: 0;
  padding: 0;
}

/*BUTTON*/
.data-collector .buttons-container .p-button {
  border-radius: 0;
}
