.toggle-button-custom {
    margin:0;
    padding:0;
    position:relative;
    transform:scale(60%);
}

/*BUTTON FRAME*/
.toggle-button-custom .p-inputswitch .p-inputswitch-slider {
    /*OFF background*/
    background: #FF8080;
    border-radius: 50px;
}

.toggle-button-custom .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    /*ON background*/
    background: #A0D995;
}

/*BUTTON BALL*/
.toggle-button-custom .p-inputswitch .p-inputswitch-slider:before {
    /*OFF ball color*/
    border-radius: 50px;
    background: rgb(255, 255, 255);
}
.toggle-button-custom .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
    /*ON ball color*/
    background: #ffffff;
}

.p-inputswitch :hover {
    /*OFF background*/
    background: #FF8080 !important;
}

.p-inputswitch-checked :hover {
    /*ON ball color*/
    background: #A0D995 !important;
}
