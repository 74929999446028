.page-load-context {
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    overflow:hidden;
}

.page-load-dialog.p-dialog {
    box-shadow: none;
    border: none;
}

.page-load-dialog .p-dialog-content:last-of-type{
    padding:0;
    background: none;
}