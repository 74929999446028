.import-window {
    display:flex;
    width:100%;
    height:100%;
    flex-direction: column;
    gap:10px;
}
.import-window .status-window {
    flex-basis:0;
    flex-grow:1;
}

.import-window  .p-fileupload .p-fileupload-buttonbar {
    padding: 0.5rem 1rem;
    display:flex;
    justify-content:flex-start;
    gap:0;
}
.import-window .p-button {
    padding:3px;
    font-size:15px;
}

.import-window .upload-window .item-template {
    margin:10px 0;
    text-align: left;

    display:flex;
    justify-content:space-between;
    gap:15px;
}