.header-full-window {
  width: 100%;
  background-color: var(--color-primary-blue);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
}

.header-full-window .header-full-window-left-side {
  padding: 5px;
  display: flex;
  gap: 20px;
  align-items: center;
}

.header-full-window .header-full-window-left-side .p-button {
  padding: 0.3rem 0.5rem;
  background-color: transparent !important;
  border: none;
  font-weight: 600 !important;
}

.header-full-window .header-full-window-left-side .p-button a {
  color: var(--color-primary-white) !important;
}

.header-full-window .header-full-window-left-side .p-button:hover {
  background-color: transparent !important;
}

.header-full-window .header-full-window-left-side .p-button a:hover {
  color: var(--color-grey-400) !important;
}

.header-full-window .header-full-window-left-side .p-highlight a {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
  color: var(--color-grey-400) !important;
}

.header-full-window .button-secondary-homemade {
  background: transparent !important;
  border: none;
  color: var(--color-primary-white) !important;
}

.header-full-window .p-button.button-secondary-homemade:hover {
  color: var(--color-grey-400) !important;
  background: transparent !important;
}
.header-full-window .p-button.button-secondary-homemade:active {
  background: #005eb5 !important;
  color: var(--color-grey-400) !important;
  border: 1px solid #005eb5 !important; 
}
