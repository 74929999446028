.full-window-tool-invoker{
    display:flex;
    flex-direction: column;
    gap:12px;

    .tool-invoker-button-container {

    }
}

.full-window-tool-invoker .address-bar {
    justify-content: center;
    display:flex;
    margin-top:8px;
    gap:5px;
}

.full-window-tool-invoker .address-bar .p-button {
    transform:scale(93%);
}

.full-window-tool-invoker .address-bar .p-float-label{
    flex-basis:0;
    flex-grow:1;
}

.full-window-tool-invoker .logs {
    font-family: Roboto, serif,Arial;
    font-size: 15px;
    overflow-x: hidden;
    overflow-y: auto;
    line-height: 1.15;
}