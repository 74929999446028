table {
    border-collapse:collapse;
}

table th,table td {
    padding: 4px 11px;
    border: 1px solid #7f8585;
}

blockquote {
    margin: 0;
    padding-left: 2em;
    border-left: 0.5em #eee solid;
}

code {
    background-color: rgb(65, 65, 75);
    border-radius: 3px;
}

hr {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid rgb(61, 61, 66);
}