.full-window {
    width:100%;
    height:100%;

    display:flex;
    flex-direction: row;
    justify-content:stretch;
    gap:5px;
}

.full-window .text-area {
    display:flex;
    flex-direction: column;
    gap:5px;
    width:100%;
}

.full-window .button-container {
    display:flex;
    flex-direction: column;
    gap:15px;

    justify-content:center;
}

.text-container .p-button.p-button-sm {
    padding: 2px 8px;
}

.text-container {
    display:flex;
    justify-content:space-between;
    align-items: flex-end;
}