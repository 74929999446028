.disable-select {
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}

.text-alignment {
    margin-bottom:10px;
}

.reader-name {
    word-break-wrap: wrap;
    overflow-wrap: anywhere;
}