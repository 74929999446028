.full-window-loading-measurement-table {
    width: 100%;
    height: 100%;
    display:flex;
    flex-direction: column;
}

.full-window-loading-measurement-table .header-side {
    padding: 0 5px 10px 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap:10px;
}

/*BUTTON COLOR GREEN AND RED TOGGLE*/
.full-window-loading-measurement-table .header-side .p-togglebutton {
    transform: scale(90%);
}

.full-window-loading-measurement-table .header-side .p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):active {
    background: #6c757d;
    color:white;
}

.full-window-loading-measurement-table .header-side .p-togglebutton.p-highlight:hover {
    background: #9fdaa8;
}

.full-window-loading-measurement-table .header-side .p-togglebutton.p-highlight:active {
    background: #9fdaa8;
    color:white;
}

.full-window-loading-measurement-table .header-side .p-togglebutton.p-highlight {
    background: #9fdaa8;
}

.full-window-loading-measurement-table .data-table-window {
    height: calc(100vh - 280px);
}

.full-window-loading-measurement-table .header-side .choices-div-flex {
    display:flex;
    gap:15px;
    align-items: center;
}

.flex-with-gap {
    display:flex;
    gap:5px;
}

.right-side-functions {
    display:flex;
    gap :10px;
    align-items: center;
    justify-content: center;

    .even-smaller-button {
        transform:scale(90%);
    }
}