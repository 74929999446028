:root {
    --left-side-width: 230px;
    --toolbar-width: 40px;
  }
  
  .data-writer{
      display: flex;
      height:100vh;
  }
  
  .data-writer .left-side {
      display: flex;
      flex-direction: column;
      width: var(--left-side-width);
      background: #23263b;
  }
  
  .data-writer .list-box {
      margin: 3px;
      min-height:0;
      flex-basis: 0;
      flex-grow:1;
      position:relative;
  }
  
  
  .data-writer .left-side .p-listbox .p-listbox-header {
      padding: 0;
  }
  .data-writer .p-listbox .p-listbox-list .p-listbox-item {
     padding: 0 0 0 15px;
  }
  
  .data-writer .right-side .json-window {
      background-color:#1e1e1e;
  }
  
  .data-writer .right-side {
      padding: 3px 0 0 0;
      display: flex;
      flex-direction: column;
      background: #23263b;
      width: calc(100% - var(--left-side-width) - var(--toolbar-width));
  }
  
  .data-writer .buttons-container {
      display: flex;
      flex-shrink:0;
      justify-content: space-between;
      background: #23263b;
  }
  
  .data-writer .buttons-container .buttons-left-container {
      margin: 5px;
      display: flex;
      flex-wrap: wrap;
      flex-shrink: 0;
      justify-content: left;
      gap: 5px;
  }
  
  
  .data-writer .tab-view-window {
      flex-basis: 0;
      flex-grow: 1;
      bottom: 0;
  
      display:flex;
      flex-direction:column;
  }
  
  .data-writer .tab-view-window .function-buttons-container {
      display:flex;
      justify-content:space-between;
      min-height:30px;
  }
  
  .data-writer .tab-view-window .function-buttons-container-container {
      display:flex;
  }
  
  
  .data-writer .p-listbox .p-listbox-list .p-listbox-item.p-highlight {
      background: #1e1e1e;
      color:white;
      font-weight: bolder;
  }
  
  .data-writer .p-listbox .p-listbox-list .p-listbox-item:focus {
      outline:0 none;
      outline-offset: 0;
      box-shadow: none;
  }
  
  .data-writer .tab-view-window .function-buttons-container-container .function-buttons-container-container-button {
      transform:scale(90%);
  }
  
  .data-writer .tab-view-window .button-container-scheme-picker {
      display:flex;
  }
  
  .data-writer .tab-view-window .button-container-scheme-picker .tab-view-button {
      background: #4f5459;
      display:flex;
      justify-content:center;
      align-items:center;
      transition: 0.2s;
      cursor: pointer;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      padding:5px 10px;
      min-width:90px;
  }
  
  .data-writer .tab-view-window .json-window {
      flex-basis: 0;
      flex-grow: 1;
      bottom: 0;
  }
  
  .data-writer .tab-view-window .button-container-scheme-picker .first-button {
      border-radius: 15px 0 0 0;
      border-right:  1px solid #1e1e1e;
  }
  .data-writer .tab-view-window .button-container-scheme-picker .last-button {
      border-radius: 0 15px 0 0;
      border-left: 1px solid #1e1e1e;
  }
  .data-writer .tab-view-window .button-container-scheme-picker .tab-view-button:hover {
      background: #2f2f2f;
  }
  .data-writer .tab-view-window .button-container-scheme-picker .tab-view-button.active {
      background: #1e1e1e;
      font-weight: bolder;
      transition: 0.3s;
  }
  
  #f-button {
      border-right:  1px solid #1e1e1e;
  }
  
  .tool-box-container {
      padding: 5px 4px 5px 0;
      background: #23263b;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      width: var(--toolbar-width);
  }
  
  .tool-box-container .tool-box-top-container,.tool-box-container .tool-box-bottom-container {
      display: flex;
      flex-direction: column;
      gap:8px;
  }
  
  .data-writer .left-side .list-box-loading {
      width:100%;
      height:100%;
      display:flex;
      justify-content: center;
      align-items: center;
      background-color: #2a323d;
      border-radius:4px;
      border: 1px solid #3f4b5b;
  }
  
  