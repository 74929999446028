.p-button.add-pipeline-button {
  padding: 4px 10px;
}


.add-pipeline-button {
  background: #6c757d;
  border: 1px solid #1e1e1e;
  color: white;
}

.p-button.add-pipeline-button:hover {
  background: #5d6267;
  border: 1px solid #1e1e1e;
  color: white;
}

.p-button.add-pipeline-button:active {
  background: #5d6267;
  color: white;
  border: 1px solid #1e1e1e;
}



