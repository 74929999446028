:root {
  --color-primary-blue: #007df2;
  --color-primary-black: #000000;
  --color-primary-grey: #ced8d9;
  --color-primary-white: #ffffff;
  --color-secondary-heat: #d95648;
  --color-secondary-pet: #e0c05b;
  --color-secondary-bms: #2bbd66;
  --color-secondary-air: #616bf2;
  --color-secondary-dry: #e97451;
  --color-secondary-boiler: #9acd32;
  --color-secondary-cold: #46bbc2;
  --color-secondary-waste: #b358c9;
  --color-blue-600: #003e79;
  --color-blue-500: #005eb5;
  --color-blue-400: #007df2;
  --color-blue-300: #2997ff;
  --color-blue-200: #5eb1ff;
  --color-blue-100: #94cbff;
  --color-grey-700: #2e3a3c;
  --color-grey-600: #5c7577;
  --color-grey-500: #abbcbe;
  --color-grey-400: #ced8d9;
  --color-grey-300: #dae2e3;
  --color-grey-200: #e7ebec;
  --color-grey-100: #f3f5f6;
}

.App {
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  overflow: hidden;
}

.appHeader {
  flex-shrink: 0;
}

.appBody {
  flex-wrap: nowrap;
  flex-grow: 1;
}

.choose-window-full-size {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30vh 0 0 0;
}

.choose-window-full-size .inner-picker {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  margin: 0;
}

.choose-window-full-size .inner-picker .text-padding {
  padding: 0 0 5px 3px;
}

.choose-window-full-size .inner-picker .select-div {
  display: flex;
}

.choose-window-full-size .inner-picker .select-div .p-selectbutton .p-button {
  border: 1px solid #3f4b5b;
}

.choose-window-full-size
  .inner-picker
  .select-div
  .p-selectbutton
  .p-button:last-of-type {
  border-color: #3f4b5b;
  border-style: solid;
  border-width: 1px 0 1px 0;
  border-radius: 0;
}

.choose-window-full-size .inner-picker .select-div .input-class {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.choose-window-full-size .inner-picker .select-div .p-inputtext {
  padding: 0.3rem 0.75rem;
  border-radius: 0 4px 4px 0;
  width: 100%;
}

.choose-window-full-size .inner-picker .select-div *:focus {
  outline: none;
  box-shadow: none;
  border: 1px solid #3f4b5b;
}

.choose-window-full-size .p-button {
  padding: 0.3rem 0.5rem;
}

.choose-window-full-size .p-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.choose-window-full-size .disable-color-change:disabled {
  background: #5d6267;
  color: white;
  border: 1px solid #1e1e1e;
}

.choose-window-full-size .p-card .p-card-footer {
  padding: 10px 0 0 0;
}

.choose-window-full-size .p-card .p-card-content {
  padding: 0 0 10px 0;
}

.choose-window-full-size .title-and-image {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.p-button {
  color: var(--color-primary-white) !important;
  border: none !important;
  background-color: var(--color-primary-black) !important;
}

.p-button:hover,
.p-button:focus {
  background-color: var(--color-primary-grey) !important;
  color: var(--color-primary-black) !important;
}

.p-button-success {
  background-color: var(--color-secondary-bms) !important;
  color: var(--color-primary-black) !important;
  border: none !important;
}

.p-listbox {
  background-color: var(--color-grey-700) !important;
}

.p-inputswitch .p-inputswitch-slider::before {
  background: var(--color-primary-black) !important;
}

.p-inputswitch .p-inputswitch-slider {
  background: var(--color-secondary-heat) !important;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: var(--color-secondary-bms) !important;
}

.p-listbox-item {
  color: var(--color-primary-white) !important;
  background: transparent !important;
}

.p-listbox-item.p-highlight {
  background: var(--color-grey-600) !important;
  color: var(--color-primary-white) !important;
  font-weight: bolder !important;
  margin-left: 5px !important;
  border-radius: 8px 0px 0px 8px !important;
}

.App,
.p-component,
.p-listbox,
.p-inputswitch,
.p-button {
  font-family: "Inter Tight", sans-serif !important;
}

.p-dialog .p-dialog-content,
.p-dialog .p-dialog-footer,
.p-dialog .p-dialog-header {
  background-color: var(--color-grey-700) !important;
}

.p-selectbutton .p-button {
  background: transparent !important;
  color: var(--color-primary-white) !important;
}

.p-selectbutton .p-highlight {
  color: var(--color-primary-grey) !important;
  font-weight: 800;
  border-radius: 0px;
  border-bottom: 1px solid var(--color-grey-500) !important;
}

.p-menu {
  background-color: var(--color-grey-700) !important;
}

.list-box {
  margin-bottom: 48px !important;
}

.p-dropdown {
  background-color: var(--color-primary-black) !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  background: var(--color-grey-700) !important;
  color: var(--color-primary-white) !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item:hover {
  background: var(--color-grey-500) !important;
  color: var(--color-primary-white) !important;
}

.p-dropdown-panel .p-dropdown-items .p-highlight {
  background: var(--color-grey-500) !important;
  color: var(--color-primary-white) !important;
}

.p-inputtext {
  background-color: var(--color-primary-black) !important;
  color: var(--color-primary-white) !important;
}

.p-selectable-row {
  background-color: var(--color-grey-600) !important;
}

.p-selectable-row:hover {
  background: var(--color-grey-500) !important;
}

.p-datatable-table .p-datatable-thead tr th {
  background-color: var(--color-grey-600) !important;
}

.p-datatable-table .p-datatable-tbody tr {
  background-color: var(--color-grey-600) !important;
}

.p-datatable-tbody .p-highlight {
  background: var(--color-grey-500) !important;
  color: var(--color-primary-white) !important;
}

.p-paginator {
  background-color: var(--color-grey-700) !important;
}

.p-paginator .p-paginator-element {
  color: var(--color-primary-white) !important;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background-color: var(--color-primary-blue);
  border: none;
}

.p-multiselect {
  background-color: var(--color-primary-black) !important;
}

.p-multiselect-item, .p-multiselect-header {
  background-color: var(--color-grey-700) !important;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  color: var(--color-primary-white) !important;
}

.p-multiselect-token {
  background-color: var(--color-primary-blue) !important;
  color: var(--color-primary-white) !important;
}

.p-checkbox-box.p-highlight {
  border: 2px solid var(--color-primary-white) !important;
  background-color: var(--color-primary-blue) !important;
  color: var(--color-primary-white) !important;
}

.p-checkbox-box.p-highlight .p-checkbox-icon {
  color: var(--color-primary-white) !important;
}

.p-tooltip-text {
  background-color: var(--color-grey-700) !important;
  color: var(--color-primary-white) !important;
}

