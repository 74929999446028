.full-window-writer-tester {
    width: 100%;
    height: 100%;
    gap: 10px;
    display: flex;
    flex-direction: column;

    .buttons-container {
        gap: 10px;
        display: flex;
        justify-content: space-between;
        flex-grow: 0;

        .p-inputtext.identifier-input {
            padding: 0.3rem;
        }
    }

    .identifier-add {
        display: flex;
        gap: 5px;
        justify-content: flex-start;
        align-items: center;
        flex-shrink: 0;
    }

    .data-table-flex {
        flex-grow: 1;
        max-height: 75vh;
        overflow: auto;

        .table-title-column-body {
            display: flex;
            gap: 5px;
        }
        .p-datatable-responsive-scroll > .p-datatable-wrapper {
            overflow-x: visible;
        }
        .not-found {
            color: red;
        }
    }

    .cell-edit-class.p-inputtext {
        padding: 0.2rem 0.5rem;
    }

    .right-side-functions {
        display:flex;
        gap :10px;

        .even-smaller-button {
            transform:scale(95%);
        }
    }
}

